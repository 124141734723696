@mixin unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin taphighlight {
    -webkit-tap-highlight-color: transparent;
}

@mixin overflow-touch {
    -webkit-overflow-scrolling: touch;
}

@mixin input {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.5;
    color: currentColor;
    text-align: left;
    cursor: pointer;
    background: transparent;
    border: 1px solid $color-text-soft;
    border-radius: $border-radius;
    outline: none;
    transition: border-color 0.3s ease-in-out 0s;
}

@mixin formbox {
    background: white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-soft;
}

@mixin animateText {
    opacity: 0;
    transition: opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateY(25px) scale(0.8);
}

@mixin jump {
    width: 6px;
    background: $color-link;
    border-radius: $border-radius;
    box-shadow: 0 0 16px 0 #0000003b;
    &:not(:first-child) {
        margin-left: 3px;
    }
}
