













































@import '@design';

.input {
    @include input;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
.placeholder {
    max-width: 80%;
    text-align: center;
}
